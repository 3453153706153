function setQueryString() {
    var inputSearch = document.getElementById('searchInput').value;
    var currentPath = document.location.href;

    // check if querystring exists in currentpath  with includes - includes not supported in IE < 11
    if(currentPath.includes("?queryStr=")){
        var qryStrPos = currentPath.indexOf("?queryStr=");
        currentPath = currentPath.substr(0,73);
        document.location.href = currentPath+"?queryStr="+inputSearch;
    } else {
        document.location = document.location.href+"?queryStr="+inputSearch
    }
}
$(document).ready(function() {
    $('#headerSearch').submit(function(event) {
      var currentAttribute = $('#searchIcon').attr('href');
      var newurl = currentAttribute + "?queryStr=" + $('#headerSearchInput').val();
      event.preventDefault();
      window.location.href = newurl;
    });
    var windowsize = $(window).outerWidth();
    // sticky navigation after scrolling a certain amount
    var subNavContainer = $(".navigation");
    var backToTopBtn = $("#backToTopButton");
    var subNavContainerOffset = subNavContainer.offset();

    if(windowsize >= 1022){
        $(window).scroll(function() {
            var windowOffset = $(this).scrollTop();

            if(subNavContainerOffset.top  <= (windowOffset + 20)){
                subNavContainer.addClass("stickyNavigation");

            }
            else if(subNavContainerOffset.top > windowOffset && subNavContainer.hasClass("stickyNavigation")){
                subNavContainer.removeClass("stickyNavigation");

            }
        });
    }

    // Back to Top button
    $(window).scroll(function(){
        var windowOffset = $(this).scrollTop();
        if(subNavContainer.hasClass("stickyNavigation") || windowOffset > 90){
            backToTopBtn.css("display", "block")
        }
        else{
            backToTopBtn.css("display", "none")
        }
    });

    // Back to Top smooth animation

    $("#backToTopButton").click(function(){
        console.log("BackToTop clicked");
        $("html, body").animate({scrollTop: 0}, 500)
    });


        var inputSearch = document.getElementById('searchInput');
        var inputSearchVal = inputSearch.value;
        var regex = /%20/g;
        var inputDisplayValue = inputSearchVal.replace(regex," ");
        if(inputSearchVal.includes("%20")){
            document.getElementById('searchInput').value = inputDisplayValue;
        }
        else if(inputSearchVal.includes("Str=")){
            document.getElementById('searchInput').value = inputDisplayValue.replace("Str=", " ");
        }
    var headerInputSearch = document.getElementById('headerSearchInput');
    var headerInputSearchVal = headerInputSearch.value;
    var headerInputSearchDisplayValue = headerInputSearchVal.replace(regex," ");
    if(inputSearchVal.includes("%20")){
        document.getElementById('searchInput').value = headerInputSearchDisplayValue;
    }
    else if(inputSearchVal.includes("Str=")){
        document.getElementById('searchInput').value = headerInputSearchDisplayValue.replace("Str=", " ");
    }

});

// showDropDown subpages in the dialogue for the about in the nav - same as the Language