$(document).ready(function() {
    /////////////////////////////////////////////
    // set up owl carousel - home page slider
    /////////////////////////////////////////////
    const owl = $("#sliderContainer");
    // get the window size for utility
    var windowsize = $(window).outerWidth();

    if (owl.length > 0) {
        // set up slider
        owl.owlCarousel({
            items: 1,
            loop: true,
            nav: false,
            dots: true,
            autoplay: true,
            autoplayHoverPause: true,
            autoplayTimeout: 6200,
            smartSpeed: 900
        });

        // vertical align - center
        // the texts in slider
        function positionSliderTitles() {
            const sliderContainer = $("#sliderContainer").height();

            $("#sliderContainer h1").each(function () {
                const element = $(this)

                element.css("paddingTop", (sliderContainer - element.height())/2 + "px");
            });
        }
        // invoke vertical align onload
        positionSliderTitles();

        /////////////////////////////////
        // invoke design recalculations
        // on window resize
        $(window).resize(function(){
            positionSliderTitles();
        });
    }
    // Language menu
    // Language menu
    $("#headerLanguages").click(function() {
        const clickedEl = $(this);
        const dropdownMenu = $('.languageDropdown');
        const dropDownArrow = clickedEl.find(".fas");

        if(clickedEl.hasClass("isOpen")){
            clickedEl.removeClass("isOpen");
            dropdownMenu.removeClass('isOpen');
            dropDownArrow.removeClass("dropdown-arrow-animation-up");
            dropDownArrow.addClass("dropdown-arrow-animation-down");
        } else {
            clickedEl.addClass("isOpen");
            dropdownMenu.addClass('isOpen');
            dropDownArrow.addClass("dropdown-arrow-animation-up");
            dropDownArrow.removeClass("dropdown-arrow-animation-down");
        }

    });



    // Navigation Menu Mobile

    $("#burger-menu").click(function(){
    const navigationMobile = $('.navigation');
    const headerRightNavMobile = $('#headerMobileRight');
    if($(this).hasClass('rotateTheBurger')){
        $(this).removeClass('rotateTheBurger');
        headerRightNavMobile.removeClass("isOpenMobile");
        navigationMobile.removeClass("isOpenMobile");

        headerRightNavMobile.addClass("isHiddenMobile");
        navigationMobile.addClass("isHiddenMobile");
        document.body.style.overflow = 'auto';
        document.body.style.overflowX = 'hidden';
        document.body.style.position = 'initial';
    }
    else{
        $(this).addClass('rotateTheBurger');
        document.body.style.overflow ="hidden";
        document.body.style.position = 'relative';
        document.body.style.height = '100%';
        navigationMobile.css('overflow-y','auto');
        headerRightNavMobile.addClass("isOpenMobile");
        navigationMobile.addClass("isOpenMobile");
        headerRightNavMobile.removeClass("isHiddenMobile");
        navigationMobile.removeClass("isHiddenMobile");
        justifyLinksInContainerMobile('#headerLinks','.headerLinks');
    }
})
    // check browser version
    var browser = (function() {
        var test = function(regexp) {
            return regexp.test(window.navigator.userAgent);
        }
        switch (true) {
            case test(/edg/i): return "edge";
            case test(/opr/i) && (!!window.opr || !!window.opera): return "opera";
            case test(/chrome/i) && !!window.chrome: return "chrome";
            case test(/trident/i): return "ie";
            case test(/firefox/i): return "firefox";
            case test(/safari/i): return "safari";
            default: return "other";
        }
    })();
    function justifyLinksInContainer(container,linkItem){
    const catNavContainer = $(container);
    const catNavLinkItem = $(linkItem);
    var totalLinksWidth = 0;
    // basis of the catNavContainer's width
    var catNavContainerWidth = catNavContainer.width();
    // map through each anchor tag taking it's width and storing it in the totalLinksWidth var
    $(linkItem).each(function(index){
        totalLinksWidth +=($(this).width())
    });
    var isPaddingEnough = true;
    var paddingCounter = 0;
    if(totalLinksWidth < catNavContainerWidth){
        while(isPaddingEnough){
            catNavLinkItem.css('paddingRight', paddingCounter);
            catNavLinkItem.last().css('paddingRight','0px');
            if(catNavContainer.outerHeight() <= 80){
                paddingCounter++;
            }
            else{
                isPaddingEnough = false;
                if(catNavContainer.outerHeight() > 80){
                    if(browser==="ie"){
                        if(localStorage.getItem("locale") == "fr") {
                            paddingCounter -= 3;
                        }
                        else{
                            paddingCounter -=1;
                        }
                    }
                    else{
                        console.log(browser);
                    }

                    if(localStorage.getItem("locale") == "fr") {
                        paddingCounter -= 3;
                    }
                    else{
                        paddingCounter -=1;
                    }
                    catNavLinkItem.css('paddingRight', paddingCounter);
                    catNavLinkItem.last().css('paddingRight','0px');
                }

            }
        }
    }
    else {
        catNavLinkItem.css('width','150px');
        catNavLinkItem.css('paddingRight', paddingCounter) ;
        totalLinksWidth = 0;
        $(linkItem).each(function(index){
            totalLinksWidth +=($(this).width())
        })
        while(isPaddingEnough){
            catNavLinkItem.css('paddingRight', paddingCounter);
            if(catNavContainer.outerHeight() <= 80){
                paddingCounter++;
            }
            else{
                paddingCounter--;
                catNavLinkItem.css('paddingRight', paddingCounter) ;
                isPaddingEnough = false;
            }
        }
        }
    }
    function justifyLinksInContainerMobile(container,linkItem){
        const catNavContainer = $(container);
        const catNavLinkItem = $(linkItem);
        var totalLinksWidth = 0;
        // basis of the catNavContainer's width
        var catNavContainerWidth = catNavContainer.width();
        // map through each anchor tag taking it's width and storing it in the totalLinksWidth var
        $(linkItem).each(function(index){
            totalLinksWidth +=($(this).width())
        });
        var isPaddingEnough = true;
        var paddingCounter = 0;
        if(totalLinksWidth < catNavContainerWidth){
            while(isPaddingEnough){
                catNavLinkItem.css('paddingRight', paddingCounter);
                catNavLinkItem.last().css('paddingRight','0px');
                if(catNavContainer.outerHeight() <= 80){
                    paddingCounter++;
                }
                else{
                    isPaddingEnough = false;
                    if(catNavContainer.outerHeight() > 80){
                        if(browser==="ie"){
                            if(localStorage.getItem("locale") == "fr") {
                                paddingCounter -= 1;
                            }
                            else{
                                paddingCounter -=1;
                            }
                        }
                        else{
                            console.log(browser);
                        }

                        if(localStorage.getItem("locale") == "fr") {
                            paddingCounter -= 1;
                        }
                        else{
                            paddingCounter -=1;
                        }
                        catNavLinkItem.css('paddingRight', paddingCounter);
                        catNavLinkItem.last().css('paddingRight','0px');
                    }

                }
            }
        }
        else {
            catNavLinkItem.css('paddingRight', paddingCounter) ;
            totalLinksWidth = 0;
            $(linkItem).each(function(index){
                totalLinksWidth +=($(this).width())
            })
            while(isPaddingEnough){
                catNavLinkItem.css('paddingRight', paddingCounter);
                if(catNavContainer.outerHeight() <= 80){
                    paddingCounter++;
                }
                else{
                    paddingCounter--;
                    catNavLinkItem.css('paddingRight', paddingCounter) ;
                    isPaddingEnough = false;
                }
            }
        }
    }
    if(windowsize >= 1022){
        justifyLinksInContainer('#navigationContainer', '.navigationLink');
    }
});
